import * as types from '../action_types/userActionTypes';

export const setUser = (user) => ({
    type: types.SET_USER,
    user
});

export const updateUser = (user) => ({
    type: types.UPDATE_USER,
    user
});

export const removeUser = () => ({
    type: types.REMOVE_USER
});

export const setToken = (token) => ({
    type: types.SET_TOKEN,
    token
});

export const setUserProfilePicture = (profile_picture) => ({
    type: types.SET_USER_PROFILE_PICTURE,
    profile_picture
});

export const setUserCGU = (cgu) => ({
    type: types.SET_USER_CGU,
    cgu
});