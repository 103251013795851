//module
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
// components
import { SelectorItemObject } from '../../../components/form/item/SelectorItem';
import PrintButton from '../../../components/button/PrintButton';
import TableAccount from '../../../components/table/TableAccount';
import Loading from '../../../components/Loading';
//action
import {
	setAccountTransactions,
	setAccountExercices,
	setAccountCurrentExercice,
	setAccountNumber,
} from '../../../action_creators/accountActionCreators';
import { setLog } from '../../../action_creators/logActionCreators';
// functions
import { getRequest, responseManagment } from '../../../functions/ajax';
import { printTable, getSelectFilters } from '../../../functions/print';

class MyTenantAccount extends Component {
	_isMounted = false;

	constructor(props) {
		super(props);

		this.state = {
			is_loading: false,
		};

		this.responseManagment = responseManagment.bind(this);
		this.goBack = this.goBack.bind(this);
		this.handleExercice = this.handleExercice.bind(this);
		this.handlePrint = this.handlePrint.bind(this);
		this.setOwnPrintFilters = this.setOwnPrintFilters.bind(this);
	}

	UNSAFE_componentWillMount() {
		const account = this.props.account;
		if (!account.exercices.length) {
			this.getAccount();
		} else if (
			account.current_exercice !== account.history.transactions.exercice
			|| !account.transactions.length
		) {
			this.updateAccount();
		}
		// Do nothing if exercices have not change and there are already transactions amounts
	}
	componentDidMount() {
		this._isMounted = true;
	}
	componentWillUnmount() {
		this._isMounted = false;
	}

	componentDidUpdate(prevProps) {
		if (prevProps.account.current_exercice !== this.props.account.current_exercice) {
			this.updateAccount();
		}
	}

	async getAccount() {
		if (!this.props.account.transactions.length) {
			this.setState({ is_loading: true });
			const response = await getRequest(
				`/compte-locataire?activity=${this.props.leases.current.activity}`,
				this.props.user.token
			);
			if (this.responseManagment(response)) {
				this.props.setAccountTransactions(response.compte);
				this.props.setAccountExercices(response.exercices);
				this.props.setAccountNumber(response.compteNumber);
			}
		
			if (this._isMounted) {
				this.setState({ is_loading: false });
			}
		}
	}

	async updateAccount() {
		this.setState({ is_loading: true });
		const account = this.props.account;
		const response = await getRequest(
			`/compte-locataire?`
				+ `activity=${this.props.leases.current.activity}`
				+ `&start=${account.exercices[account.current_exercice].start}`
				+ `&end=${account.exercices[account.current_exercice].end}`,
			this.props.user.token
		);
		if (this.responseManagment(response)) {
			this.props.setAccountTransactions(response.compte);
			if (!account.number) {
				this.props.setAccountNumber(response.compteNumber);
			}
		} else {
			this.props.setAccountTransactions([]);
		}
		
        if (this._isMounted) {
            this.setState({ is_loading: false });
        }
	}

	handleExercice(evt) {
		this.props.setAccountCurrentExercice(evt.target.value);
	}

	handlePrint(evt) {
		printTable(
			'table-account',
			'mon-compte-locataire.pdf',
			'Mon compte locataire',
			this.props.leases.current,
			this.setOwnPrintFilters
		);
	}

	setOwnPrintFilters() {
		return `Compte : ${this.props.account.number} | ${getSelectFilters()}`;
	}

	goBack(evt) {
		this.props.history.push(`/lease/${this.props.match.params.id}/account/`);
	}

	render() {
		return (
			<div className="grid grid-1 my-tenant-account">
				<div className="card">
					<div className="card-header card-header-with-back">
						<button className="back-btn" onClick={this.goBack}>
							Revenir en arrière
						</button>
						<h2>Mon compte locataire</h2>
						<PrintButton
							print={this.handlePrint}
						/>
					</div>
					<div className="card-body table-wrapper">
						<div className="standfirst">
							{this.props.account.number ? (
								<h3>Compte {this.props.account.number}</h3>
							) : null}
							{this.props.account.exercices.length ? (
								<SelectorItemObject
									id="select-account-exerice"
									label="Période"
									current={this.props.account.current_exercice}
									options={this.props.account.exercices}
									callback={this.handleExercice}
								/>
							) : null}
						</div>
						{this.state.is_loading === false ? (
							this.props.account.transactions.length ? (
								<TableAccount
									cols={[
										'LIBELLÉS',
										'DATE',
										'DÉBIT',
										'CRÉDIT',
										'SOLDE',
										'PIÈCES',
									]}
									table={this.props.account.transactions}
									pieces={true}
								/>
							) : (
								<p>Il n'y a aucune opération sur votre compte à cette période.</p>
							)
						) : (
							<Loading />
						)}
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		leases: state.leases,
		user: state.user,
		account: state.account,
	};
};
const mapDispatchToProps = {
	setAccountTransactions,
	setAccountExercices,
	setAccountCurrentExercice,
	setAccountNumber,
	setLog,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MyTenantAccount));
