import {SET_LOG, REMOVE_LOG} from '../action_types/logActionTypes';

function logReducer(state = {}, action) {
    let nextState;

    switch (action.type) {
        case SET_LOG:
            nextState = action.log;
            return nextState || state;

        case REMOVE_LOG:
            return {};

        default:
            return state;
    }
}

export default logReducer;