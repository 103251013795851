//module
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';

class AsideNav extends Component {
    render() {
        if (this.props.leases.current) {
            return (
                <nav>
                    <ul>
                        <li>
                            <NavLink to={`/lease/${this.props.leases.current.id}/dashboard`}>
                                Tableau de bord
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to={`/lease/${this.props.leases.current.id}/data`}>
                                Mes données
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to={`/lease/${this.props.leases.current.id}/incidents`}>
                                Incidents
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to={`/lease/${this.props.leases.current.id}/account`}>
                                Mon compte
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to={`/lease/${this.props.leases.current.id}/files`}>
                                Mes fichiers
                            </NavLink>
                        </li>
                        <li className="contact-link">
                            <NavLink to={`/lease/${this.props.leases.current.id}/contact`}>
                                Contact
                            </NavLink>
                        </li>
                    </ul>
                </nav>
            );
        } else {
            return (
                <nav>
                    <ul>
                        <li>
                            <NavLink exact to="/">Mes locations</NavLink>
                        </li>
                        <li>
                            <NavLink to="/info">Mes informations</NavLink>
                        </li>
                    </ul>
                </nav>
            );
        }
    }
}

const mapStateToProps = (state) => {
    return {
        leases: state.leases
    }
}

export default withRouter(connect(mapStateToProps)(AsideNav));
