import * as types from '../action_types/incidentsActionTypes';

const initialState = {
    nb_incidents: 0,
    nb_pages: 0,
    pages: {},
    in_progress: true
};

function incidentsReducer(state = initialState, action) {
    let nextState;

    switch (action.type) {

        case types.SET_INCIDENTS:
            nextState = {
                ...state,
                // Necessary to copy all object impacted
                pages: {
                    ...state.pages
                }
            };
            nextState.pages[action.current_page] = action.incidents;
            return nextState || state;

        case types.SET_NB_PAGES_INCIDENTS:
            nextState = {
                ...state,
                nb_pages: action.nb
            };
            return nextState || state;

        case types.SET_NB_INCIDENTS:
            nextState = {
                ...state,
                nb_incidents: action.nb
            };
            return nextState || state;

        case types.SET_INCIDENTS_IN_PROGRESS:
            nextState = {
                ...state,
                in_progress: action.in_progress
            };
            return nextState || state;

        case types.REMOVE_INCIDENTS:
            nextState = {
                ...initialState,
                in_progress: state.in_progress
            };
            return nextState || state;

        case types.REMOVE_INCIDENTS_AND_FILTERS:
            return initialState;

        default:
            return state;
    }
}

export default incidentsReducer;