//module
import React from "react";

const MenuBurger = props => (
	<button
		type="button"
		className={`menu-burger ${props.active ? "close" : ""}`}
		onClick={props.callback}
	>
		<span className="menu-burger-slice"></span>
		<span className="menu-burger-slice"></span>
		<span className="menu-burger-slice"></span>
	</button>
);

export default MenuBurger;
