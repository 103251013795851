
// functions
import { getRequest } from './ajax';
// constants
import { STORED_LEASES } from '../constants/localStorage';

/**
 * Get leases
 * @param {string} token
 * @return {object}
 * Needs to be bind
 */
export async function getLeases(token) {
    const response = await getRequest(`/leases`, token);
    if (this.responseManagment(response)) {
        if (Object.keys(response.leases).length) {
            localStorage.setItem(
                STORED_LEASES,
                JSON.stringify(response.leases)
            );
            this.props.setLeases(response.leases);
            return response.leases;
        } else {
            this.props.setLog({
                type: "success",
                message: "Vous n'avez plus de baux actif.",
            });
        }
    }
    return [];
}