// module
import React from 'react';
import PropTypes from 'prop-types';
// component
import Form from './Form';
import DateFieldset from './item/DateFieldset';
import FileItem from './item/FileItem';

class FormInsurance extends Form {
	constructor(props) {
		super(props);

		this.initialState = {
			start: null,
			end: null,
			files: [],
			// shouldReset: true,
			nextDate: null,
		};

		this.state = this.initialState;
		// this.state.shouldReset = false;

		if (props.insurance.end) {
			this.state.nextDate = this.getNextDate(props.insurance.end);
		}

		this.handleChangeFiles = this.handleChangeFiles.bind(this);
		// this.hasReset = this.hasReset.bind(this);
	}

	componentDidUpdate(prevProps) {
		if (prevProps.insurance.end !== this.props.insurance.end) {
			this.setState({
				nextDate: this.props.insurance.end
					? this.getNextDate(this.props.insurance.end)
					: null,
			});
		}
	}

	checkDates() {
		if (
			!this.checkDate(this.state.start, 'début')
			|| !this.checkDate(this.state.end, 'fin')
		) {
			return false;
		}

		let start = new Date([
			this.state.start.year,
			this.state.start.month,
			this.state.start.day,
		]);
		let end = new Date([
			this.state.end.year,
			this.state.end.month,
			this.state.end.day,
		]);

		if (start.getTime() > end.getTime()) {
			this.props.setLog({
				type: 'error',
				message: `La date de début doit être plus ancienne que la date de fin, ou du même jour.`,
			});
			return false;
		}

		return true;
	}

	checkDate(date, label) {
		if (
			!date
			|| date.day.length !== 2
			|| date.month.length !== 2
			|| date.year.length !== 4
			|| date.day < 1
			|| date.month < 1
			|| date.year < 1
		) {
			this.props.setLog({
				type: 'error',
				message: `Date de ${label} incomplète. Veuillez respecter le format : jj/mm/aaaa.`,
			});
			return false;
		}

		return true;
	}

	handleChangeFiles(files) {
		if (this.state.files.length > 0) {
			// There is already two images in this component
			this.props.setLog({
				type: 'error',
				message: "Veuillez retirer une image, avant d'en ajouter une nouvelle.",
			});
		} else {
			this.setState({ files: files });
		}
	}

	handleSubmit(evt) {
		evt.preventDefault();
		if (this.checkDates()) {
			this.props.callback({ ...this.state });
			// this.resetInputs();
		}
	}

	// hasReset() {
	// 	this.setState({ shouldReset: false });
	// }

	/** @param {string} date */
	getNextDate(date) {
		let [day, month, year] = date.split('/');
		let nextDate = new Date(year, parseInt(month) - 1, day);
		// add day, auto change (28|29|30|31)/m to 01/m+1
		nextDate.setDate(nextDate.getDate() + 1);
		day = nextDate.getDate();
		month = nextDate.getMonth() + 1;
		year = nextDate.getFullYear();
		if (day < 10) {
			day = '0' + day;
		} else {
			day = day.toString();
		}
		if (month < 10) {
			month = '0' + month;
		} else {
			month = month.toString();
		}
		year = year.toString();
		return { day, month, year };
	}

	render() {
		return (
			<form className="form" onSubmit={this.handleSubmit}>
				<div className="form-item-list">
					<DateFieldset
						day_id="start_day"
						month_id="start_month"
						year_id="start_year"
						label="Date de début"
						// shouldReset={this.state.shouldReset}
						// hasReset={() => this.hasReset()}
						callback={start => this.setState({ start })}
						defaultDate={this.state.nextDate}
						required
					/>
					<DateFieldset
						day_id="end_day"
						month_id="end_month"
						year_id="end_year"
						label="Date de fin"
						// shouldReset={this.state.shouldReset}
						// hasReset={() => this.hasReset()}
						callback={end => this.setState({ end })}
					/>
					<FileItem
						name="file-insurance"
						title="PDF"
						label_btn="Sélectionner"
						type="pdf"
						nb_max={1}
						max_Mo={10}
						callback={this.handleChangeFiles}
						setLog={log => this.props.setLog(log)}
					/>
				</div>
				<div className="submit-button-wrapper">
					<button className="btn submit-button btn-orange" type="submit">
						Valider
					</button>
				</div>
			</form>
		);
	}
}

FormInsurance.propTypes = {
	callback: PropTypes.func.isRequired,
	setLog: PropTypes.func.isRequired,
	insurance: PropTypes.object.isRequired,
};

export default FormInsurance;
