import * as types from '../action_types/adminActionTypes';

function adminReducer(state = {}, action) {
    let nextState;

    switch (action.type) {

        case types.SET_ADMIN:
            nextState = action.admin;
            return nextState || state;

        case types.REMOVE_ADMIN:
            return {};

        default:
            return state;
    }
}

export default adminReducer;