// module
import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Modal extends Component {
	constructor(props) {
		super(props);

		this.state = {
			app: null,
		};

		this.clickOnOverlay = this.clickOnOverlay.bind(this);
		this.clickOnClose = this.clickOnClose.bind(this);
	}

	// Make app unscrollable if modal is visible
	componentDidMount() {
		let app = document.getElementsByClassName('app')[0];
		if (this.props.is_visible) {
			app.classList.add('unscrollable');
		}
		this.setState({ app });
	}

	// Toggle app scrollability according to modal visibility
	componentDidUpdate(prevProps) {
		if (prevProps.is_visible !== this.props.is_visible) {
			if (this.props.is_visible) {
				this.state.app.classList.add('unscrollable');
			} else {
				this.state.app.classList.remove('unscrollable');
			}
		} else if (
			this.props.is_visible
			&& !this.state.app.classList.contains('unscrollable')
		) {
			this.state.app.classList.add('unscrollable');
		}
	}

	// Hide modal if overlay is clicked
	clickOnOverlay(evt) {
		if (evt.target.id === this.props.id && this.props.callback) {
			this.props.callback(false);
		}
	}
	clickOnClose(evt) {
		if (this.props.callback) {
			this.props.callback(false);
		}
	}

	render() {
		return (
			<div
				id={this.props.id}
				className={'overlay' + (this.props.is_visible ? '' : ' disappeared')}
				onClick={this.clickOnOverlay}
			>
				<div className="modal">
					<div className="modal-scrollbar">
						<div className="inner-modal">
							{this.props.callback ? (
								<button
									className="close-modal"
									onClick={this.clickOnClose}
								>
									<img
										alt="fermer"
										src={`/images/pictogrammes/close-blue.svg`}
									/>
								</button>
							) : null}
							{this.props.children}
						</div>
					</div>
				</div>
			</div>
		);
	}
}

Modal.propTypes = {
	id: PropTypes.string.isRequired,
	is_visible: PropTypes.bool.isRequired,
	callback: PropTypes.func,
};

export default Modal;
