// module
import React, { Component } from "react";
import { connect } from "react-redux";
import { removeLog } from "../action_creators/logActionCreators";

class Log extends Component {
	constructor(props) {
		super(props);

		this.state = {
			hidden: false
		};
	}

	componentDidMount() {
		if (this.props.log.type === "error") {
			console.error(this.props.log.message);
		} else {
			console.log(this.props.log.message);
		}
		// Logs are autoremoved
		setTimeout(() => {
			this.setState({ hidden: true });
			setTimeout(() => {
				this.props.removeLog();
			}, 300); // css transition time
		}, 4000); // time to read log
	}

	render() {
		return (
			<div
				className={
					"user-log-wrapper" + (this.state.hidden ? " hidden" : "")
				}
			>
				<span className={`user-log ${this.props.log.type}`}>
					{this.props.log.message}
				</span>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	log: state.log
});

const mapDispatchToProps = {
	removeLog
};

export default connect(mapStateToProps, mapDispatchToProps)(Log);
