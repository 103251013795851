//module
import React from "react";

const Notification = props => (
	<div className="notification">
		<h2>{props.title}</h2>
		<p>{props.children}</p>
	</div>
);

export default Notification;
