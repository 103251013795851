import * as types from '../action_types/userActionTypes';

function userReducer(state = {}, action) {
	let nextState;

	switch (action.type) {
		case types.SET_USER:
			nextState = action.user;

			return nextState || state;

		case types.UPDATE_USER:
			nextState = {
				...state,
				...action.user,
			};

			return nextState || state;

		case types.REMOVE_USER:
			nextState = {};

			return nextState || state;

		case types.SET_TOKEN:
			nextState = {
				...state,
				token: action.token,
			};

			return nextState || state;

		case types.SET_USER_PROFILE_PICTURE:
			nextState = {
				...state,
				profile_picture: action.profile_picture,
			};

			return nextState || state;

		case types.SET_USER_CGU:
			nextState = {
				...state,
				cgu: action.cgu,
			};

			return nextState || state;

		default:
			return state;
	}
}

export default userReducer;
