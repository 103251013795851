//module
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
// components
import { SelectorItemObject } from '../../../../components/form/item/SelectorItem';
import GraphColumns from '../../../../components/graph/GraphColumns';
import Loading from '../../../../components/Loading';
//action
import { setLog } from '../../../../action_creators/logActionCreators';
import {
	setGlobalAccountAmounts,
	setAccountExercices,
	setAccountCurrentExercice,
} from '../../../../action_creators/accountActionCreators';
// functions
import { getRequest, responseManagment } from '../../../../functions/ajax';
// constants
import { blue, green, red } from '../../../../constants/colors';

class TenantAccountCard extends Component {
	_isMounted = false;

	constructor(props) {
		super(props);

		this.state = {
			is_loading: false,
			is_half_loading: false,
		};

		this.responseManagment = responseManagment.bind(this);
		this.handleExercice = this.handleExercice.bind(this);
	}
	componentDidMount() {
		this._isMounted = true;
	}
	componentWillUnmount() {
		this._isMounted = false;
	}

	async UNSAFE_componentWillMount() {
		const account = this.props.account;
		if (!account.exercices.length) {
			this.getAccount();
		} else if (
			account.current_exercice !== account.history.global.exercice
			|| !Object.keys(account.global_amounts).length
		) {
			this.updateAccount();
		}
		// Do nothing if exercices have not change and there are already global amounts
	}

	componentDidUpdate(prevProps) {
		if (prevProps.account.current_exercice !== this.props.account.current_exercice) {
			this.updateAccount();
		}
	}

	async getAccount() {
		this.setState({ is_loading: true });
		const response = await getRequest(
			`/compte-locataire-graph?activity=${this.props.leases.current.activity}`,
			this.props.user.token
		);
		if (
			this.responseManagment(response) &&
			// for displayed 'Aucun ... n'est disponible...'
			// better here than in render
			!(response.appele === 0 && response.regle === 0 && !response.exercices.length)
		) {
			this.props.setAccountExercices(response.exercices);
			this.props.setGlobalAccountAmounts({
				called: response.appele,
				paid: response.regle,
				solde: response.solde,
			});
		}

		if (this._isMounted) {
			this.setState({ is_loading: false });
		}
	}

	async updateAccount() {
		this.setState({ is_half_loading: true });
		const account = this.props.account;
		const response = await getRequest(
			`/compte-locataire-graph?`
				+ `activity=${this.props.leases.current.activity}`
				+ `&start=${account.exercices[account.current_exercice].start}`
				+ `&end=${account.exercices[account.current_exercice].end}`,
			this.props.user.token
		);
		if (this.responseManagment(response)) {
			this.props.setGlobalAccountAmounts({
				called: response.appele,
				paid: response.regle,
				solde: response.solde,
			});
		} else {
			this.props.setGlobalAccountAmounts({});
		}

		if (this._isMounted) {
			this.setState({ is_half_loading: false });
		}
	}

	handleExercice(evt) {
		this.props.setAccountCurrentExercice(evt.target.value);
	}

	render() {
		return (
			<div
				className={
					'card unscrollable sliced-card'
					+ (Object.keys(this.props.account.global_amounts).length
						? ' sliced-card-graph'
						: '')
				}
			>
				<div className="card-header composed-card-header">
					<h2>Mon compte locataire</h2>
					{this.props.account.exercices.length ? (
						<SelectorItemObject
							id="select-account-exerice"
							label="Période"
							current={this.props.account.current_exercice}
							options={this.props.account.exercices}
							callback={this.handleExercice}
						/>
					) : null}
				</div>
				<div className="card-bodys-wrapper">
					<div
						className={
							'half-card card-body'
							+ (Object.keys(this.props.account.global_amounts).length
							&& this.state.is_loading === false
								? ' parent-graph'
								: '')
						}
					>
						{this.state.is_loading === false ? (
							Object.keys(this.props.account.global_amounts).length ? (
								<Fragment>
									<GraphColumns
										id="soldeGraph"
										cols={1}
										colors={[blue]}
										datas={[
											[
												'Solde à la date du jour',
												this.props.account.global_amounts.solde
													|| null,
											],
										]}
									/>
								</Fragment>
							) : (
								<p>Aucun solde n'est disponible pour cette période.</p>
							)
						) : (
							<Loading />
						)}
					</div>
					<div
						className={
							'half-card card-body'
							+ (Object.keys(this.props.account.global_amounts).length
							&& this.state.is_loading === false
								? ' parent-graph'
								: '')
						}
					>
						{this.state.is_loading === false
						&& this.state.is_half_loading === false ? (
							Object.keys(this.props.account.global_amounts).length ? (
								<Fragment>
									<GraphColumns
										id="accountGraph"
										cols={2}
										colors={[red, green]}
										datas={[
											[
												'Montant Appelé sur la période',
												this.props.account.global_amounts.called
													|| null,
											],
											[
												'Montant Reglé sur la période',
												this.props.account.global_amounts.paid
													|| null,
											],
										]}
									/>
									<div className="btn-wrapper">
										<Link
											className="btn"
											to={`/lease/${this.props.match.params.id}/account/tenant_account`}
										>
											Accéder
										</Link>
									</div>
								</Fragment>
							) : (
								<p>Aucun montant n'est disponible pour cette période.</p>
							)
						) : (
							<Loading />
						)}
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		leases: state.leases,
		user: state.user,
		account: state.account,
	};
};
const mapDispatchToProps = {
	setGlobalAccountAmounts,
	setAccountExercices,
	setAccountCurrentExercice,
	setLog,
};

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(TenantAccountCard)
);
