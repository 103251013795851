//module
import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Pagination extends Component {
    constructor(props) {
        super(props);

        this.sendNextPage = this.sendNextPage.bind(this);
        this.sendPrevPage = this.sendPrevPage.bind(this);
    }

    sendClickedPage(nb) {
        this.props.callback(nb);
    }

    sendNextPage() {
        if (this.props.current_page < this.props.page_max) {
            this.sendClickedPage(this.props.current_page + 1);
        }
    }

    sendPrevPage() {
        if (this.props.current_page > 1) {
            this.sendClickedPage(this.props.current_page - 1);
        }
    }

    render() {
        let pager_items = [];
        let min_pager = this.props.current_page - 2;
        let max_pager = this.props.current_page + 2;
        // limit min_pager at 1
        if (min_pager < 1) {
            min_pager = 1;
        }
        // limit max_pager at this.props.page_max
        if (max_pager > this.props.page_max) {
            max_pager = this.props.page_max;
        }
        if (this.props.current_page > 1) {
            pager_items.push((
                <li key={0}>
                    <button
                        className="pager"
                        title="Page précédente"
                        onClick={this.sendPrevPage}
                    >&lt;</button>
                </li>
            ));
        }
        // Set first item if it isn't
        if (min_pager > 1) {
            pager_items.push((
                <li key={1}>
                    <button
                        className="pager"
                        onClick={evt => this.sendClickedPage(1)}
                    >1</button>
                </li>
            ));
        }
        // Set "..."
        if (min_pager > 2) {
            pager_items.push((
                <li key={2}>
                    <button
                        className="pager"
                        onClick={evt => this.sendClickedPage(min_pager - 1)}
                    >...</button>
                </li>
            ));
        }
        // Get up to 2 items before and after current item
        for (let i = min_pager; i <= max_pager; i++) {
            pager_items.push((
                <li key={i}>
                    {this.props.current_page === i
                        ? <button className="pager active">{i}</button>
                        : <button
                            className="pager"
                            onClick={evt => this.sendClickedPage(i)}
                        >{i}</button>
                    }
                </li>
            ));
        }
        // Set "..."
        if (max_pager < this.props.page_max - 1) {
            pager_items.push((
                <li key={this.props.page_max - 1}>
                    <button
                        className="pager"
                        onClick={evt => this.sendClickedPage(max_pager + 1)}
                    >...</button>
                </li>
            ));
        }
        // Set last item if it isn't
        if (max_pager < this.props.page_max) {
            pager_items.push((
                <li key={this.props.page_max}>
                    <button
                        className="pager"
                        onClick={evt => this.sendClickedPage(this.props.page_max)}
                    >
                        {this.props.page_max}
                    </button>
                </li>
            ));
        }
        if (this.props.current_page < this.props.page_max) {
            pager_items.push((
                <li key={this.props.page_max + 1}>
                    <button
                        className="pager"
                        title="Page suivante"
                        onClick={this.sendNextPage}
                    >&gt;</button>
                </li>
            ));
        }

        return (
            <ul className="pagination">
                {pager_items}
            </ul>
        );
    }
}

Pagination.propTypes = {
    current_page: PropTypes.number.isRequired,
    page_max: PropTypes.number.isRequired,
    callback: PropTypes.func.isRequired,
};

export default Pagination;