// module
import React from 'react';
// components
import PictoTable from '../PictoTable';

const TableMandate = props => (
	<table className={`table horizontal table-mandate`}>
		<tbody>
			<tr>
				<th>Créancier</th>
				<td>{props.table.creancier}</td>
			</tr>
			<tr>
				<th>Débiteur</th>
				<td>{props.table.debiteur}</td>
			</tr>
			<tr>
				<th>Date de début</th>
				<td>{props.table.start}</td>
			</tr>
			<tr>
				<th>Document</th>
				<td>
					{props.table.files.length
						? props.table.files.map((file, i) => (
								<PictoTable key={i} file={file} />
						  ))
						: ' - '}
				</td>
			</tr>
		</tbody>
	</table>
);

export default TableMandate;
