//module
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
// components
import TableReminders from '../../../components/table/TableReminders';
import TableCalls from '../../../components/table/TableCalls';
import TableMandate from '../../../components/table/TableMandate';
import Loading from '../../../components/Loading';
import TenantAccountCard from './cards/TenantAccountCard';
//action
import {
	setShortReminders,
	setShortCalls,
	setMandate,
} from '../../../action_creators/accountActionCreators';
import { setLog } from '../../../action_creators/logActionCreators';
// functions
import { getRequest, responseManagment } from '../../../functions/ajax';

class MyAccount extends Component {
	_isMounted = false;

	constructor(props) {
		super(props);

		this.state = {
			are_loading_calls: false,
			are_loading_reminders: false,
			is_loading_mandate: false,
		};

		this.responseManagment = responseManagment.bind(this);
	}

	UNSAFE_componentWillMount() {
		this.getReminders();
		this.getCalls();
		this.getMandate();
	}
	componentDidMount() {
		this._isMounted = true;
	}
	componentWillUnmount() {
		this._isMounted = false;
	}

	async getReminders() {
		if (!this.props.reminders.shortList.length) {
			this.setState({ are_loading_reminders: true });
			const response = await getRequest(
				`/relance?`
					+ `activity=${this.props.leases.current.activity}`
					+ `&acquitter=${false}`
					+ `&max=4`,
				this.props.user.token
			);
			if (this.responseManagment(response)) {
				this.props.setShortReminders(response.relances);
			}

			if (this._isMounted) {
				this.setState({ are_loading_reminders: false });
			}
		}
	}

	async getCalls() {
		if (!this.props.calls.shortList.length) {
			this.setState({ are_loading_calls: true });
			const response = await getRequest(
				`/calls?`
					+ `activity=${this.props.leases.current.activity}`
					+ `&lease=${this.props.leases.current.id}`
					+ `&paid=${false}`
					+ `&max=4`,
				this.props.user.token
			);
			if (this.responseManagment(response)) {
				this.props.setShortCalls(response.calls);
			}

			if (this._isMounted) {
				this.setState({ are_loading_calls: false });
			}
		}
	}

	async getMandate() {
		if (!this.props.account.mandate.length) {
			this.setState({ is_loading_mandate: true });
			const response = await getRequest(
				`/debit-mandate?activity=${this.props.leases.current.activity}`,
				this.props.user.token
			);
			if (this.responseManagment(response)) {
				this.props.setMandate(response.debit_mandate);
			}

			if (this._isMounted) {
				this.setState({ is_loading_mandate: false });
			}
		}
	}

	render() {
		const { calls, reminders, leases, account } = this.props;
		return (
			<div className="grid grid-4 my-account">
				<div className="card">
					<div className="card-header composed-card-header">
						<h2>Mes appels</h2>
						<em>impayés</em>
					</div>
					<div className="card-body table-wrapper">
						{this.state.are_loading_calls === false ? (
							calls.shortList.length ? (
								<TableCalls
									cols={['DATE', 'MONTANT', 'RESTANT', 'DOCUMENTS']}
									table={calls.shortList}
									activity={leases.current.activity}
								/>
							) : null
						) : (
							<Loading />
						)}
						<div className="btn-wrapper">
							<Link
								className="btn"
								to={`/lease/${this.props.match.params.id}/account/calls`}
							>
								Voir tout
							</Link>
						</div>
					</div>
				</div>
				<div className="card">
					<div className="card-header composed-card-header">
						<h2>Mes relances</h2>
						<em>impayés</em>
					</div>
					<div className="card-body table-wrapper">
						{this.state.are_loading_reminders === false ? (
							reminders.shortList.length ? (
								<TableReminders
									cols={[
										'DATE',
										'MONTANT',
										'RESTANT',
										'TYPE',
										'DOCUMENTS',
									]}
									table={reminders.shortList}
									activity={leases.current.activity}
								/>
							) : null
						) : (
							<Loading />
						)}
						<div className="btn-wrapper">
							<Link
								className="btn"
								to={`/lease/${this.props.match.params.id}/account/reminders`}
							>
								Voir tout
							</Link>
						</div>
					</div>
				</div>
				<TenantAccountCard />
				<div className="card">
					<div className="card-header">
						<h2>Mandat de prélèvement</h2>
					</div>
					<div className="card-body">
						{this.state.is_loading_mandate === false ? (
							Object.keys(account.mandate).length ? (
								<TableMandate
									table={account.mandate}
									cols={[
										'CRÉANCIER',
										'DÉBITEUR',
										'DATE DEBUT',
										'DOCUMENT',
									]}
								/>
							) : (
								<p>Aucun mandat de prélèvement disponible.</p>
							)
						) : (
							<Loading />
						)}
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		leases: state.leases,
		user: state.user,
		calls: state.calls,
		reminders: state.reminders,
		account: state.account,
	};
};

const mapDispacthToProps = {
	setShortCalls,
	setShortReminders,
	setMandate,
	setLog,
};

export default withRouter(connect(mapStateToProps, mapDispacthToProps)(MyAccount));
