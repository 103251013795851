//module
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
// components
import AsideNav from './AsideNav';
import MenuBurger from './MenuBurger';
import Loading from './Loading';

class Aside extends Component {
	constructor(props) {
		super(props);

		this.state = {
			is_visible: false,
			app: null,
		};

		this.handleMenuBurger = this.handleMenuBurger.bind(this);
		this.handleClickOut = this.handleClickOut.bind(this);
	}

	componentDidMount() {
		this.setState({
			app: document.getElementsByClassName('app')[0],
		});
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.is_visible !== this.state.is_visible) {
			if (this.state.is_visible) {
				this.state.app.classList.add('unscrollable');
			} else if (this.state.app.classList.contains('unscrollable')) {
				this.state.app.classList.remove('unscrollable');
			}
		}
	}

	handleClickOut(evt) {
		if (evt.target.className === 'in' || evt.target.tagName === 'A') {
			this.setState({ is_visible: false });
		}
	}

	handleMenuBurger(evt) {
		this.setState({ is_visible: !this.state.is_visible });
	}

	render() {
		let profile_picture;

		if (this.props.user.profile_picture) {
			profile_picture = this.props.user.profile_picture;
		} else {
			profile_picture = `/images/pictogrammes/image-white.svg`;
		}

		return (
			<aside
				className={this.state.is_visible ? 'in' : ''}
				onClick={this.handleClickOut}
			>
				<MenuBurger callback={this.handleMenuBurger} active={this.state.in} />
				<div className="aside-content-wrapper">
					<div className="profile-img-wrapper">
						<Link to="/info" title="Mon compte">
							{this.props.user.profile_picture
							|| this.props.user.profile_picture === false ? (
								/* eslint-disable-next-line jsx-a11y/img-redundant-alt */
								<img
									src={profile_picture}
									className="profile-img img-fluid"
									alt="Image de profile"
								/>
							) : (
								<div className="profile-img">
									<Loading />
								</div>
							)}
							<span className="sr-only">Lien vers mon compte</span>
						</Link>
					</div>
					<AsideNav />
					<div className="aside-bottom">
						<a
							href="https://play.google.com/store/apps/details?id=com.espacelocataire&hl=fr"
							target="_blank"
							rel="nofollow noopener noreferrer"
						>
							<img
								src={`/images/pictogrammes/playstore-white.svg`}
								alt="logo du Play Store"
								className="img-fluid aside-playstore-img"
							/>
							<span className="sr-only">Play Store</span>
						</a>
						<Link to="/help">
							<img
								src={`/images/pictogrammes/help-white.svg`}
								alt="bouton d'aide"
								className="img-fluid aside-tutorial-img"
							/>
							<span className="sr-only">Page d'aide</span>
						</Link>
						{
							!!this.props.leases.current 
							&& this.props.leases.current.sousTraitance === true && (
								<img
									src={`/images/pictogrammes/logo-mygestionpro.svg`}
									alt="logo du site de présentation de la gestion propriétaire"
									className={`img-fluid aside-application-logo img-white`}
								/>
							)
						}
					</div>
				</div>
			</aside>
		);
	}
}

const mapStateToProps = state => {
	return {
		user: state.user,
		leases: state.leases,
	};
};

export default withRouter(connect(mapStateToProps)(Aside));
