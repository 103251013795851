import {
	SET_CALLS,
	SET_SHORT_CALLS,
	SET_CALLS_EXERCICES,
	SET_CALLS_CURRENT_EXERCICE,
	REMOVE_CALLS,
} from '../action_types/accountActionTypes';

const initialState = {
	exercices: [],
	current_exercice: 0,
	list: [],
	shortList: [],
};

function accountReducer(state = initialState, action) {
	let nextState;

	switch (action.type) {
		case SET_CALLS:
			nextState = {
				...state,
				list: action.calls,
			};
			return nextState || state;

		case SET_SHORT_CALLS:
			nextState = {
				...state,
				shortList: action.calls,
			};
			return nextState || state;

		case SET_CALLS_EXERCICES:
			nextState = {
				...state,
				exercices: action.exercices,
			};
			return nextState || state;

		case SET_CALLS_CURRENT_EXERCICE:
			nextState = {
				...state,
				current_exercice: action.exercice,
			};
			return nextState || state;

		case REMOVE_CALLS:
			return initialState;

		default:
			return state;
	}
}

export default accountReducer;
