// module
import React, { Component } from 'react';
import PropTypes from 'prop-types';
// components
import { TableBase, colorOddRows } from './Table';
import FileDownloadedFromAPI from '../FileDownloadedFromAPI';
import TdAmount from './TdAmount';

class TableReminders extends Component {
	render() {
		return (
			<TableBase className="table-reminders" cols={this.props.cols}>
				<tbody>
					{this.props.table.map((el, i) => (
						<tr key={i} className={colorOddRows(i)}>
							<td className="date">{el.date}</td>
							<TdAmount value={el.amount} negative={true} />
							<TdAmount value={el.leftAmount} negative={true} />
							<td>{el.type}</td>
							<td>
								{el.facture === true && (
									<FileDownloadedFromAPI
										uri={
											`/relance-facture?`
											+ `activity=${this.props.activity}`
											+ `&id_relance=${el.id_relance}`
											+ `&id_relance_element=${el.id_relance_element}`
										}
										filename={`${el.type}-relance-${el.date}-${el.id_relance}`}
										className="picto-table-wrapper"
									>
										<img
											src="/images/pictogrammes/invoice-blue.svg"
											alt="Télécharger la facture"
											title="Facture de la relance"
											className={`picto-table`}
										/>
									</FileDownloadedFromAPI>
								)}
								{el.courrier === true && (
									<FileDownloadedFromAPI
										uri={
											`/relance-courrier?`
											+ `activity=${this.props.activity}`
											+ `&id_relance=${el.id_relance}`
											+ `&id_relance_element=${el.id_relance_element}`
										}
										filename={`${el.type}-relance-${el.date}-${el.id_relance}`}
										className="picto-table-wrapper"
									>
										<img
											src="/images/pictogrammes/pdf-blue.svg"
											alt="Télécharger le courrier"
											title="Courrier de la relance"
											className={`picto-table`}
										/>
									</FileDownloadedFromAPI>
								)}
							</td>
						</tr>
					))}
				</tbody>
			</TableBase>
		);
	}
}

TableReminders.propTypes = {
	// ...Table.propTypes,
	activity: PropTypes.number.isRequired,
};

export default TableReminders;
