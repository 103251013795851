// modules
import { combineReducers } from 'redux';
// reducers
import user from './userReducer';
import leases from './leasesReducer';
import account from './accountReducer';
import calls from './callsReducer';
import reminders from './remindersReducer';
import notifications from './notificationsReducer';
import lots from './lotsReducer';
import admin from './adminReducer';
import incidents from './incidentsReducer';
import file_manager from './filesReducer';
import log from './logReducer';

const reducerCombiner = combineReducers({
    leases, user,
    account, reminders, calls,
    admin, lots, file_manager,
    notifications,
    incidents,
    log,
});

export default reducerCombiner;