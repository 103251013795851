import * as types from '../action_types/filesActionTypes';

const initialState = {
    folders: [],
    files: [],
};

function filesReducer(state = initialState, action) {
    let nextState;

    switch (action.type) {

        case types.REMOVE_FILES:
            return initialState;

        case types.SET_FILES:
            nextState = {
                ...state,
                folders: action.folders,
                files: action.files
            };
            return nextState || state;

        default:
            return state;
    }
}

export default filesReducer;