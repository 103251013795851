//module
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts';
// functions
import { formatNumbers } from '../../functions';
// constants
import { white, grey, black } from '../../constants/colors';

class GraphColumns extends Component {
	componentDidMount() {
		Highcharts.setOptions({
			lang: {
				// add a space to thousands
				thousandsSep: ' ',
			},
		});
		Highcharts.chart(this.props.id, {
			chart: {
				type: 'column',
			},
			title: {
				text: null,
			},
			plotOptions: {
				column: {
					borderRadius: 10,
					shadow: false,
					groupPadding: 0,
					pointPadding: 0.15,
					minPointLength: 25,
				},
				series: {
					dataLabels: {
						enabled: true,
						inside: true,
						color: white,
						overflow: 'allow',
						crop: false,
						style: {
							fontSize: '18px',
							textOutline: null,
							fontWeight: 500,
						},
						formatter: function () {
							return `${formatNumbers(this.y)}\u00A0€`;
						},
					},
					states: {
						hover: {
							enabled: false,
						},
					},
				},
			},
			legend: { enabled: false },
			series: [
				{
					colorByPoint: true,
					data: this.props.datas,
				},
			],
			colors: this.props.colors,
			tooltip: { enabled: false },
			xAxis: {
				type: 'category',
				lineWidth: 0,
				gridLineWidth: 0,
				lineColor: 'transparent',
				minorTickLength: 0,
				tickLength: 0,
				title: { enabled: false },
				labels: {
					style: {
						fontSize: '18px',
						color: black,
					},
				},
			},
			yAxis: {
				lineWidth: 0,
				gridLineWidth: 0,
				lineColor: 'transparent',
				minorTickLength: 0,
				tickLength: 0,
				title: {
					enabled: false,
				},
				labels: {
					enabled: false,
				},
			},
			credits: {
				enabled: false,
			},
		});
	}

	render() {
		return (
			<div
				id={this.props.id}
				className={`graph graph-col graph-${this.props.cols}-col`}
			></div>
		);
	}
}

GraphColumns.propTypes = {
	id: PropTypes.string.isRequired,
	cols: PropTypes.any.isRequired,
	colors: PropTypes.arrayOf(PropTypes.string).isRequired,
	datas: PropTypes.array.isRequired,
};

export default GraphColumns;
