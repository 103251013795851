//module
import React from 'react';
// const
import { API_PDF_SRC_URL } from '../constants/api';

const PictoTable = ({ file }) => (
	<div className="file">
		<a href={API_PDF_SRC_URL + file.url} className="file-name" title={file.label}>
			<span className="sr-only">{file.label}</span>
			<img
				src={`/images/pictogrammes/pdf-grey.svg`}
				alt={`télécharger le fichier ${file.label}`}
				className="picto-table"
			/>
		</a>
	</div>
);

export default PictoTable;
