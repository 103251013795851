// module
import React, { Component } from 'react';
import PropTypes from 'prop-types';

class DateFieldset extends Component {
	constructor(props) {
		super(props);

		if (props.defaultDate) {
			this.state = { ...props.defaultDate };
		} else {
			this.state = {
				day: '',
				month: '',
				year: '',
			};
		}

		this.initialState = { ...this.state };

		this.handleDay = this.handleDay.bind(this);
		this.handleMonth = this.handleMonth.bind(this);
		this.handleYear = this.handleYear.bind(this);
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			prevProps.defaultDate !== this.props.defaultDate
			&& this.props.defaultDate
			&& !this.state.day
			&& !this.state.month
			&& !this.state.year
		) {
			this.setState({ ...this.props.defaultDate });
		}

		if (
			prevState.day !== this.state.day
			|| prevState.month !== this.state.month
			|| prevState.year !== this.state.year
		) {
			this.props.callback({ ...this.state });
		}

		if (this.props.shouldReset === true) {
			this.setState({ ...this.initialState });
			this.props.hasReset();
		}
	}

	handleDay(evt) {
		if (
			!isNaN(evt.target.value)
			&& evt.target.value <= 31
			&& evt.target.value >= 0
			&& evt.target.value.length <= 2
		) {
			this.setState({ day: evt.target.value });
			if (evt.target.value.length === 2) {
				document.getElementById(this.props.month_id).focus();
			}
		}
	}

	handleMonth(evt) {
		if (
			!isNaN(evt.target.value)
			&& evt.target.value <= 12
			&& evt.target.value >= 0
			&& evt.target.value.length <= 2
		) {
			this.setState({ month: evt.target.value });
			if (evt.target.value.length === 2) {
				document.getElementById(this.props.year_id).focus();
			}
		}
	}

	handleYear(evt) {
		if (
			!isNaN(evt.target.value)
			&& evt.target.value >= 0
			&& evt.target.value.length <= 4
		) {
			this.setState({ year: evt.target.value });
		}
	}
	render() {
		return (
			<fieldset className="date-fieldset">
				<span className="fake-label">
					{this.props.label}
					{this.props.required ? '\u00A0*' : ''}
				</span>
				<label className="sr-only" htmlFor={this.props.day_id}>
					Jour
				</label>
				<input
					type="number"
					id={this.props.day_id}
					name={this.props.day_id}
					className="day"
					placeholder="00"
					value={this.state.day}
					onChange={this.handleDay}
					required={this.props.required}
				/>
				<label className="sr-only" htmlFor={this.props.month_id}>
					Mois
				</label>
				<input
					type="number"
					id={this.props.month_id}
					name={this.props.month_id}
					className="month"
					placeholder="00"
					value={this.state.month}
					onChange={this.handleMonth}
					required={this.props.required}
				/>
				<label className="sr-only" htmlFor={this.props.year_id}>
					Année
				</label>
				<input
					type="number"
					id={this.props.year_id}
					name={this.props.year_id}
					className="year"
					placeholder="0000"
					value={this.state.year}
					onChange={this.handleYear}
					required={this.props.required}
				/>
			</fieldset>
		);
	}
}

DateFieldset.propTypes = {
	day_id: PropTypes.string.isRequired,
	month_id: PropTypes.string.isRequired,
	year_id: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	required: PropTypes.bool,
	callback: PropTypes.func.isRequired,
	shouldReset: PropTypes.bool,
	hasReset: PropTypes.func,
	defaultDate: PropTypes.object,
};

DateFieldset.defaultProps = {
	required: false,
};

export default DateFieldset;
