import * as types from '../action_types/filesActionTypes';

// MY FILES
export const setFiles = (folders, files) => ({
	type: types.SET_FILES,
	folders,
	files
});
export const removeFiles = () => ({
	type: types.REMOVE_FILES
});