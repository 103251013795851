// module
import React from 'react';
import PropTypes from 'prop-types';

function CheckboxItem(props) {
    let name = props.input.name;

    return (
        <div className="form-item form-group">
            <input
                className="form-item-check-box"
                type="checkbox"
                id={name}
                name={name}
                value={true}
                checked={props.checked}
                required={props.input.required}
                onChange={evt => props.callback(evt)}
            />
            <label
                htmlFor={name}
                className={'form-item-check-box-label'
                    + (props.checked ? ' checked' : '')
                }
                dangerouslySetInnerHTML={{ __html: props.input.label }}
            ></label>
        </div>
    );
}

CheckboxItem.propTypes = {
    input: PropTypes.object.isRequired,
    checked: PropTypes.bool,
    callback: PropTypes.func.isRequired
};

export default CheckboxItem;