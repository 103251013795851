// module
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
// component
import Notification from '../../components/Notification';
import Loading from '../../components/Loading';
//actions
import { addNotifications } from '../../action_creators/notificationsActionCreators';
import { setLog } from '../../action_creators/logActionCreators';
// functions
import { getRequest, responseManagment } from '../../functions/ajax';

class DashBoard extends Component {
	_isMounted = false;

	constructor(props) {
		super(props);

		this.state = {
			is_loading: false,
		};

		this.responseManagment = responseManagment.bind(this);
	}

	UNSAFE_componentWillMount() {
		if (!this.props.notifications.length) {
			this.getNotifications();
		}
	}
	componentDidMount() {
		this._isMounted = true;
	}
	componentWillUnmount() {
		this._isMounted = false;
	}

	async getNotifications() {
		this.setState({ is_loading: true });
		const response = await getRequest(
			`/notification?activity=${this.props.leases.current.activity}`,
			this.props.user.token
		);
		if (this.responseManagment(response)) {
			this.props.addNotifications(response.notifications);
		}
        
        if (this._isMounted) {
            this.setState({ is_loading: false });
        }
	}

	render() {
		return (
			<div
				className='grid grid-1 dashboard'
			>
				<div className="card">
					<div className="card-header">
						<h2>Notifications</h2>
					</div>
					<div className="card-body">
						{/* Starting by adding own App notifications */}
						<Notification title="Pas à pas">
							Découvrez votre accès personnalisé à votre location ! Il vous suffit de suivre le{' '}
							<a
								href={`/pdf/MYGP-Guide-Locataire.pdf`}
								// eslint-disable-next-line react/jsx-no-target-blank
								target="_blank"
							>
								guide
							</a>
						</Notification>
						<Notification title="Note de votre administrateur de bien">
							Bienvenue sur votre espace Locataire !
						</Notification>
						{/* Add API Notifications */}
						{this.state.is_loading === false ? (
							this.props.notifications.length
							? this.props.notifications.map((notification, i) => (
								<Notification title={notification.title} key={i}>
									{notification.description}
								</Notification>
							)) : null
						) : (
							<Loading />
						)}
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		leases: state.leases,
		user: state.user,
		notifications: state.notifications,
	};
};

const mapDispatchToProps = {
	addNotifications,
	setLog,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DashBoard));
