
// module
import React from 'react';
import PropTypes from 'prop-types';

function PrintButton(props) {
    return (
        <button className="btn-print" onClick={evt => props.print(evt)}>
            <img
                src={`/images/pictogrammes/printer-blue.svg`}
                alt="pictogramme d'imprimante"
            />
            <span className="sr-only">Imprimer ou exporter en pdf.</span>
        </button>
    );
}

PrintButton.propTypes = {
    print: PropTypes.func.isRequired,
};

export default PrintButton;

