//module
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
// component
import Loading from '../../components/Loading';
// actions
import { setLog } from '../../action_creators/logActionCreators';
import { setFiles } from '../../action_creators/filesActionCreators';
// functions
import { getRequest, responseManagment } from '../../functions/ajax';
// const
import { API_PDF_SRC_URL } from '../../constants/api';

class MyFiles extends Component {
	_isMounted = false;

	constructor(props) {
		super(props);

		this.state = {
			is_loading: false,
			breadcrumb: [{ label: 'FICHIERS', id: 0 }],
		};

		this.responseManagment = responseManagment.bind(this);
		this.handleFolder = this.handleFolder.bind(this);
	}

	UNSAFE_componentWillMount() {
		this.getFolders();
	}
	componentDidMount() {
		this._isMounted = true;
	}
	componentWillUnmount() {
		this._isMounted = false;
	}

	async getFolders(id) {
		this.setState({ is_loading: true });
		const response = await getRequest(
			`/gestionnaire-fichiers?`
				+ `activity=${this.props.leases.current.activity}`
				+ (id ? `&folder=${id}` : ''),
			this.props.user.token
		);
		if (this.responseManagment(response)) {
			this.props.setFiles(response.folders, response.files);
		}

		if (this._isMounted) {
			this.setState({ is_loading: false });
		}
	}

	handleFolder(evt) {
		let folder = this.props.folders[evt.currentTarget.dataset.index];
		// update breadcumb
		let breadcrumb = this.state.breadcrumb.slice();
		breadcrumb.push(folder);
		this.setState({ breadcrumb });
		// update folders
		this.getFolders(folder.id);
	}

	handleBreadCrumb(index) {
		this.getFolders(this.state.breadcrumb[index].id);
		this.setState({
			breadcrumb: this.state.breadcrumb.slice(0, index + 1),
		});
	}

	render() {
		return (
			<div className="grid grid-1 my-files">
				<div className="card">
					<div className="card-header">
						<h2>Fichiers</h2>
					</div>
					<div className="card-body">
						{this.state.breadcrumb.length ? (
							<div className="breadcrumb">
								{this.state.breadcrumb.map((folder, k) => (
									<Fragment key={k}>
										<span
											onClick={evt => this.handleBreadCrumb(k)}
											className="breadcrumb-anchor"
										>
											{folder.label}
										</span>
										/
									</Fragment>
								))}
							</div>
						) : null}
						{this.state.is_loading === false ? (
							!this.props.folders.length && !this.props.files.length ? (
								<p>Ce dossier est vide.</p>
							) : (
								<div className="folder-content">
									{this.props.folders.length
										? this.props.folders.map((folder, i) => (
												<div
													key={i}
													data-index={i}
													className="folder"
													onClick={this.handleFolder}
												>
													<img
														src={`/images/pictogrammes/folder-blue.svg`}
														alt={`dossier ${folder.label}`}
														className="picto-folder"
													/>
													<p className={'folder-name'}>
														{folder.label}
													</p>
												</div>
										  ))
										: null}
									{this.props.files.length
										? this.props.files.map((file, i) => (
												<div key={i} className="file">
													<img
														src={`/images/pictogrammes/pdf-blue.svg`}
														alt={`télécharger le fichier ${file.label}`}
														className="picto-file"
													/>
													<a
														href={API_PDF_SRC_URL + file.url}
														className="file-name"
													>
														{file.label}
													</a>
												</div>
										  ))
										: null}
								</div>
							)
						) : (
							<Loading />
						)}
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		leases: state.leases,
		user: state.user,
		folders: state.file_manager.folders,
		files: state.file_manager.files,
	};
};
const mapDispatchToProps = {
	setLog,
	setFiles,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MyFiles));
