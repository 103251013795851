// module
import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, Switch, withRouter, Redirect } from "react-router-dom";
// component
import DashBoard from "../lease/DashBoard";
import MyData from "../lease/MyData";
import Incidents from "../lease/Incidents";
import MyAccount from "../lease/account/MyAccount";
import MyCalls from "../lease/account/MyCalls";
import MyReminders from "../lease/account/MyReminders";
import MyTenantAccount from "../lease/account/MyTenantAccount";
import MyFiles from "../lease/MyFiles";
import Contact from "../lease/contact/Contact";
//actions
import { setCurrentLease } from "../../action_creators/leasesActionCreators";
import clearLeaseActions from '../../constants/clearLeaseActionList';

class LeaseRouter extends Component {
	UNSAFE_componentWillMount() {
		// Clear store from other lease datas
		Object.keys(clearLeaseActions).forEach(action => {
			this.props[action]();
		});
		if (Object.keys(this.props.leases.list).length) {
			const current_lease_id = this.props.match.params.id;
			if (current_lease_id) {
				const current_lease = this.props.leases.list[
					current_lease_id
				];
				if (current_lease) {
					this.props.setCurrentLease(current_lease);
					return;
				}
			}
		}
		this.props.history.push("/");
	}

	render() {
		if (this.props.leases.current) {
			return (
				<Switch>
					{/* DashBoard */}
					<Route exact path="/lease/:id/dashboard">
						<DashBoard />
					</Route>
					{/* Data */}
					<Route exact path="/lease/:id/data">
						<MyData />
					</Route>
					{/* Incidents */}
					<Route exact path="/lease/:id/incidents">
						<Incidents />
					</Route>
					<Route exact path="/lease/:id/incidents/:page">
						<Incidents />
					</Route>
					{/* Account */}
					<Route exact path="/lease/:id/account">
						<MyAccount />
					</Route>
					<Route exact path="/lease/:id/account/calls">
						<MyCalls />
					</Route>
					<Route exact path="/lease/:id/account/reminders">
						<MyReminders />
					</Route>
					<Route
						exact
						path="/lease/:id/account/tenant_account"
					>
						<MyTenantAccount />
					</Route>
					{/* Files */}
					<Route exact path="/lease/:id/files">
						<MyFiles />
					</Route>
					{/* Contact */}
					<Route exact path="/lease/:id/contact">
						<Contact />
					</Route>
					{/* Redirection */}
					<Route path="/lease/:id">
						<Redirect
							to={`/lease/${this.props.match.params.id}/dashboard`}
						/>
					</Route>
				</Switch>
			);
		}else{
            return null;
        }
	}
}

const mapStateToProps = state => {
	return {
		leases: state.leases
	};
};
const mapDispatchToProps = Object.assign(
	{ setCurrentLease },
	clearLeaseActions
);

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(LeaseRouter)
);
