// module
import React from 'react';
import { Link } from 'react-router-dom';

const Lease = ({ lease }) => (
	<div className={`lease-teaser`}>
		<h2>
			<Link to={`/lease/${lease.id}/dashboard`} className="extended-link">
				{lease.label}
			</Link>
		</h2>
		<div className="lease_nb_lot">
			<p>Nombre de lots loués</p>
			<span>{lease.nb_lots}</span>
		</div>
		<button className="btn">Entrer</button>
	</div>
);

export default Lease;
