// component
import Form from './Form'

class FormPassword extends Form {

    handleChange(evt) {
        super.handleChange(evt);

        if (evt.target.name === "confirmation") {
            this.checkSameness(evt);
        }
    }

    // Check if new_password === confirmation, if not block submit and inform the user
    checkSameness(evt) {
        if (this.state.new_password !== evt.target.value) {
            evt.target.setCustomValidity(
                "La confirmation de mot de passe n'est pas identique au nouveau mot de passe."
            );
        } else {
            evt.target.setCustomValidity('');
        }
    }
}

export default FormPassword;