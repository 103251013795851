// functions
import { getRequest } from "./ajax";

/**
 * Get User profile picture
 * @param {string} token
 * @return {object}
 * Needs to be bind
 */
export async function getProfilePicture(token) {
	const response = await getRequest(
		`/profile-picture?width=165&height=165`,
		token
	);
	if (this.responseBlobManagment(response)) {
		const blob = URL.createObjectURL(response);
		this.props.setUserProfilePicture(blob);
	} else {
		this.props.setUserProfilePicture(false);
	}
}
