// module
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
// components
import TableLease from '../../components/table/TableLease';
import TableAdmin from '../../components/table/TableAdmin';
import Modal from '../../components/Modal';
import FormInsurance from '../../components/form/FormInsurance';
import FormHeater from '../../components/form/FormHeater';
import Loading from '../../components/Loading';
//action
import { updateCurrentLease } from '../../action_creators/leasesActionCreators';
import { setAdmin } from '../../action_creators/adminActionCreators';
import { setLog } from '../../action_creators/logActionCreators';
// functions
import {
	getRequest,
	postRequest,
	responseManagment,
	responseBlobManagment,
} from '../../functions/ajax';

class MyData extends Component {
	_isMounted = false;

	constructor(props) {
		super(props);

		this.state = {
			is_loading_lease: false,
			is_loading_admin: false,
			is_loading_put_insurance: false,
			is_loading_put_heater: false,
			display_insurance_modal: false,
			display_heater_modal: false,
		};

		this.responseManagment = responseManagment.bind(this);
		this.responseBlobManagment = responseBlobManagment.bind(this);
		this.toggleModalInsurance = this.toggleModalInsurance.bind(this);
		this.toggleModalHeater = this.toggleModalHeater.bind(this);
		this.putInsurance = this.putInsurance.bind(this);
		this.putHeater = this.putHeater.bind(this);
	}

	UNSAFE_componentWillMount() {
		if (!this.props.leases.current.start) {
			this.getLeaseDetails();
		}
		if (!Object.keys(this.props.admin).length) {
			this.getAdmin();
		}
	}
	componentDidMount() {
		this._isMounted = true;
	}
	componentWillUnmount() {
		this._isMounted = false;
	}

	async getLeaseDetails() {
		this.setState({ is_loading_lease: true });
		const response = await getRequest(
			`/leases/${this.props.leases.current.id}`,
			this.props.user.token
		);
		if (this.responseManagment(response)) {
			this.props.updateCurrentLease(response.lease);
		}

		if (this._isMounted) {
			this.setState({ is_loading_lease: false });
		}
	}

	async getAdmin() {
		this.setState({ is_loading_admin: true });
		const response = await getRequest(
			`/property_admin?activity=${this.props.leases.current.activity}`,
			this.props.user.token
		);
		if (this.responseManagment(response)) {
			this.props.setAdmin(response.property_admin);
		}

		if (this._isMounted) {
			this.setState({ is_loading_admin: false });
		}
	}

	async putInsurance(value) {
		this.setState({ is_loading_put_insurance: true });

		const data_to_put = {
			start: `${value.start.year}-${value.start.month}-${value.start.day}`,
			end: `${value.end.year}-${value.end.month}-${value.end.day}`,
		};
		if (value.files.length) {
			data_to_put.file = value.files[0];
		}

		// Update insurance
		const response = await postRequest(
			`/insurance?activity=${this.props.leases.current.activity}`
				+ `&lease=${this.props.leases.current.id}`,
			data_to_put,
			this.props.user.token
		);

		if (this.responseManagment(response)) {
			this.props.setLog({
				type: 'success',
				message: "L'assurance a bien été renouvellée.",
			});
			this.getLeaseDetails();
		}

		if (this._isMounted) {
			this.setState({
				is_loading_put_insurance: false,
				display_insurance_modal: false,
			});
		}
	}

	async putHeater(value) {
		this.setState({ is_loading_put_heater: true });

		const data_to_put = {
			start: `${value.start.year}-${value.start.month}-${value.start.day}`,
			end: `${value.end.year}-${value.end.month}-${value.end.day}`,
		};
		if (value.files.length) {
			data_to_put.file = value.files[0];
		}

		// Update heater
		const response = await postRequest(
			`/heater?activity=${this.props.leases.current.activity}`
				+ `&lease=${this.props.leases.current.id}`,
			data_to_put,
			this.props.user.token
		);

		if (this.responseManagment(response)) {
			this.props.setLog({
				type: 'success',
				message: "Le contrat d'entretien a bien été renouvellée.",
			});
			this.getLeaseDetails();
		}

		if (this._isMounted) {
			this.setState({
				is_loading_put_heater: false,
				display_heater_modal: false,
			});
		}
	}

	toggleModalInsurance(is_visible) {
		this.setState({ display_insurance_modal: is_visible });
	}
	toggleModalHeater(is_visible) {
		this.setState({ display_heater_modal: is_visible });
	}

	render() {
		return (
			<div className="grid grid-2 my-data">
				<div className="card">
					<div className="card-header">
						<h2>Mon Bail</h2>
					</div>
					<div className="card-body table-wrapper">
						{this.state.is_loading_lease === false ? (
							this.props.leases.current.start ? (
								<Fragment>
									<TableLease table={this.props.leases.current} />
									<div className="btn-wrapper">
										<button
											className="btn btn-orange"
											onClick={evt =>
												this.toggleModalInsurance(true)
											}
										>
											Renouveller l'assurance
										</button>
										<button
											className="btn btn-orange"
											onClick={evt => this.toggleModalHeater(true)}
										>
											Renouveller le contrat d'entretien de
											chaudière ou climatisation
										</button>
									</div>
								</Fragment>
							) : (
								<p>Bail incomplet.</p>
							)
						) : (
							<Loading />
						)}
					</div>
				</div>
				<div className="card">
					<div className="card-header">
						<h2>Administrateur de bien</h2>
					</div>
					<div className="card-body table-wrapper">
						{this.state.is_loading_admin === false ? (
							Object.keys(this.props.admin).length ? (
								<TableAdmin table={this.props.admin} />
							) : (
								<p>Il n'y a pas d'administrateur de bien sur ce bien.</p>
							)
						) : (
							<Loading />
						)}
					</div>
				</div>
				{/* Modal */}
				<Modal
					id="incident-modal"
					is_visible={this.state.display_insurance_modal}
					callback={this.toggleModalInsurance}
				>
					<h2>Renouvellement assurance</h2>
					{this.state.is_loading_put_insurance === false ? (
						<FormInsurance
							insurance={this.props.leases.current.insurance || {}}
							callback={this.putInsurance}
							setLog={log => this.props.setLog(log)}
						/>
					) : (
						<Loading />
					)}
				</Modal>
				<Modal
					id="incident-modal"
					is_visible={this.state.display_heater_modal}
					callback={this.toggleModalHeater}
				>
					<h2>
						Renouvellement contrat d'entretien de chaudière ou climatisation
					</h2>
					{this.state.is_loading_put_heater === false ? (
						<FormHeater
							heater={this.props.leases.current.heater || {}}
							callback={this.putHeater}
							setLog={log => this.props.setLog(log)}
						/>
					) : (
						<Loading />
					)}
				</Modal>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		leases: state.leases,
		user: state.user,
		admin: state.admin,
	};
};
const mapDispacthToProps = {
	updateCurrentLease,
	setAdmin,
	setLog,
};

export default withRouter(connect(mapStateToProps, mapDispacthToProps)(MyData));
