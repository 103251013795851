import * as types from '../action_types/notificationsActionTypes';

export const setNotifications = (notifications) => ({
	type: types.SET_NOTIFICATIONS,
	notifications
});

export const removeNotifications = () => ({
	type: types.REMOVE_NOTIFICATIONS
});

export const addNotifications = (notifications) => ({
	type: types.ADD_NOTIFICATIONS,
	notifications
});

export const removeNotification = (index) => ({
	type: types.REMOVE_NOTIFICATION,
	index
});