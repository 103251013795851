// module
import React from 'react';

const EdgeMessage = (props) => (
	<div className="edge-message">
		<p>
			Vous utilisez actuellement une ancienne version de <b>Microsoft Edge</b>.
			Veuillez télécharger la nouvelle version pour pouvoir utiliser toute les
			fonctionnalités du site&nbsp;:{' '}
			<a href="https://www.microsoft.com/fr-fr/edge">lien de téléchargement</a>.
			Vous pouvez aussi utiliser Chrome ou Firefox.
		</p>
		<button className="close" onClick={evt => props.closeEdgeMessage(evt)}>
			<img
				alt="fermer"
				src={`/images/pictogrammes/close-grey.svg`}
			/>
		</button>
	</div>
);

export default EdgeMessage;
