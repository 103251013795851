import * as types from '../action_types/incidentsActionTypes';

export const setIncidents = (incidents, current_page) => ({
	type: types.SET_INCIDENTS,
	incidents,
	current_page
});
export const removeIncidents = () => ({
	type: types.REMOVE_INCIDENTS
});

export const setNbIncidents = (nb) => ({
	type: types.SET_NB_INCIDENTS,
	nb
});
export const setNbPagesIncidents = (nb) => ({
	type: types.SET_NB_PAGES_INCIDENTS,
	nb
});

export const setIncidentsInProgress = (in_progress) => ({
	type: types.SET_INCIDENTS_IN_PROGRESS,
	in_progress
});
export const removeIncidentsAndFilters = () => ({
	type: types.REMOVE_INCIDENTS_AND_FILTERS
});