import * as types from '../action_types/notificationsActionTypes';

const initialState = [];

function notificationsReducer(state = initialState, action) {
    let nextState;

    switch (action.type) {

        case types.SET_NOTIFICATIONS:
            nextState = action.notifications;
            return nextState || state;

        case types.REMOVE_NOTIFICATIONS:
            return initialState;

        case types.ADD_NOTIFICATIONS:
            nextState = state.concat(action.notifications);
            return nextState || state;

        case types.REMOVE_NOTIFICATION:
            nextState = state.slice();
            // In 2 lines because splice have a return value
            nextState.splice(action.index, 1);
            return nextState || state;

        default:
            return state;
    }
}

export default notificationsReducer;