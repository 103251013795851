import {SET_LOTS, REMOVE_LOTS} from '../action_types/lotsActionTypes';

function lotsReducer(state = [], action) {
    let nextState;

    switch (action.type) {
        case SET_LOTS:
            nextState = action.lots;
            return nextState || state;

        case REMOVE_LOTS:
            return [];

        default:
            return state;
    }
}

export default lotsReducer;