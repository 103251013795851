// module
import React from 'react';
// components
import PropTypes from 'prop-types';
// function
import { formatNumbers } from '../../functions';

const TableAdmin = props => (
	<table className={`table table-admin horizontal`}>
		<tbody>
			<tr>
				<th>nom</th>
				<td>{props.table.name}</td>
			</tr>
			<tr>
				<th>adresse</th>
				<td>{props.table.address.full}</td>
			</tr>
			<tr>
				<th>contact</th>
				<td>{props.table.email}</td>
			</tr>
			<tr>
				<th>téléphone</th>
				<td>{props.table.phone}</td>
			</tr>
			<tr>
				<th>mandat</th>
				<td>{props.table.start}</td>
			</tr>
			<tr>
				<th>Société</th>
				<td>
					{props.table.siret
						? `Numéro de Siret\u00A0: ${props.table.siret} | `
						: null}
					{props.table.ape ? `Code APE\u00A0: ${props.table.ape} | ` : null}
					{props.table.rcs
						? `Ville d'immatriculation\u00A0: ${props.table.rcs} | `
						: null}
					{props.table.capital
						? `Capital\u00A0: ${formatNumbers(props.table.capital)}\u00A0€`
						: null}
				</td>
			</tr>
			<tr>
				<th>Carte G</th>
				<td>{props.table.carteG}</td>
			</tr>
			<tr>
				<th>Garant</th>
				<td>
					{Object.keys(props.table.financial_guarantor).length
						? `${props.table.financial_guarantor.description} | `
						  + `Montant Maximal\u00A0: ${formatNumbers(
								props.table.financial_guarantor.amount
						  )}\u00A0€`
						: null}
				</td>
			</tr>
		</tbody>
	</table>
);

TableAdmin.propTypes = {
	table: PropTypes.object.isRequired,
	className: PropTypes.string,
};

export default TableAdmin;
